import { SmartFieldProvider } from 'components/drawers/Smartfields/types'
import { HolistiplanBuilder } from './builders/holistiplan.builder'
import { HolistiplanIcon } from './builders/holistiplan.icon'
import { NitrogenBuilder } from './builders/nitrogen.builder'
import { NitrogenIcon } from './builders/nitrogen.icon'
import { PreciseFPBuilder } from './builders/precisefp.builder'
import { PreciseFPIcon } from './builders/precisefp.icon'
import { RecallZoomBuilder } from './builders/recall-zoom.builder'
import { RecallZoomIcon } from './builders/recall-zoom.icon'
import { WealthboxBuilder } from './builders/wealthbox.builder'
import { WealthboxIcon } from './builders/wealthbox.icon'
import { ZohoBuilder } from './builders/zoho.builder'
import { ZohoIcon } from './builders/zoho.icon'
import { splitProviderAndAdvisor } from './connection.utils'

export type ConnectionConfiguration = {
  provider: ConnectionProvider
  title: ConnectionTitle
  connectionBuilder: React.ComponentType
  connectionIcon: React.ComponentType
  /** @todo deprecate in favour of `permissionKey`*/
  enabled: boolean
  permissionKey?: string
}

export const getConnectionConfig = (
  provider: ConnectionProvider | SmartFieldProvider,
): ConnectionConfiguration => {
  const providerName = splitProviderAndAdvisor(provider).provider
  return {
    ...connectionConfig[providerName as ConnectionProvider],
  }
}

export const connectionTypes = [
  {
    id: 'holistiplan',
    title: 'Holistiplan',
  },
  // 'hubspot',
  {
    id: 'riskalyze',
    title: 'Nitrogen',
  },
  {
    id: 'precisefp',
    title: 'PreciseFP',
  },
  {
    id: 'recall_zoom',
    title: 'Zoom',
  },
  {
    id: 'wealthbox-connector',
    title: 'Wealthbox (Data)',
  },
  {
    id: 'zoho',
    title: 'Zoho',
  },
] as const

export type ConnectionProvider = (typeof connectionTypes)[number]['id']
export type ConnectionTitle = (typeof connectionTypes)[number]['title']

export const connectionConfig: Record<
  ConnectionProvider,
  ConnectionConfiguration
> = {
  holistiplan: {
    provider: 'holistiplan',
    title: 'Holistiplan',
    connectionBuilder: HolistiplanBuilder,
    connectionIcon: HolistiplanIcon,
    enabled: true,
    permissionKey: 'ui.app.connectors.holistiplan',
  },
  riskalyze: {
    provider: 'riskalyze',
    title: 'Nitrogen',
    connectionBuilder: NitrogenBuilder,
    connectionIcon: NitrogenIcon,
    enabled: true,
    permissionKey: 'ui.app.connectors.riskalyze',
  },
  precisefp: {
    provider: 'precisefp',
    title: 'PreciseFP',
    connectionBuilder: PreciseFPBuilder,
    connectionIcon: PreciseFPIcon,
    enabled: true,
    permissionKey: 'ui.app.connectors.precisefp',
  },
  recall_zoom: {
    provider: 'recall_zoom',
    title: 'Zoom',
    connectionBuilder: RecallZoomBuilder,
    connectionIcon: RecallZoomIcon,
    enabled: true,
    permissionKey: 'ui.app.connectors.recall_zoom',
  },
  'wealthbox-connector': {
    provider: 'wealthbox-connector',
    title: 'Wealthbox (Data)',
    connectionBuilder: WealthboxBuilder,
    connectionIcon: WealthboxIcon,
    enabled: true,
    permissionKey: 'ui.app.connectors.wealthbox',
  },
  zoho: {
    provider: 'zoho',
    title: 'Zoho',
    connectionBuilder: ZohoBuilder,
    connectionIcon: ZohoIcon,
    enabled: true,
    permissionKey: 'ui.app.connectors.zoho',
  },
}
