import { Form, Input } from 'antd'
import { ConnectionBuilder } from '../connection.builder'
import { useConnection } from '../connection.hooks'

export const WealthboxBuilder: React.FC = () => {
  const { connection } = useConnection('wealthbox-connector')

  return (
    <ConnectionBuilder
      provider="wealthbox-connector"
      title="Wealthbox (Data)"
      learnMoreLink="https://www.wealthbox.com/"
      connection={connection}
      key2="apiKey"
      renderSettings={(form, submit) => (
        <Form onFinish={submit} form={form}>
          <Form.Item
            label="API Key"
            name="key1"
            rules={[{ required: true, message: 'Enter api key!' }]}
          >
            <Input size="large" autoFocus />
          </Form.Item>
        </Form>
      )}
    />
  )
}
