import {
  ApplySchemaAttributes,
  command,
  CommandFunction,
  extension,
  ExtensionPriority,
  htmlToProsemirrorNode,
  isElementDomNode,
  kebabCase,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  omitExtraAttributes,
  ProsemirrorAttributes,
} from '@remirror/core'

/**
 * Options available to the [[`MentionAtomExtension`]].
 */
export interface NoteOptions {}

/**
 * This is the atom version of the `MentionExtension`
 * `@remirror/extension-mention`.
 *
 * It provides mentions as atom nodes which don't support editing once being
 * inserted into the document.
 */
@extension<NoteOptions>({
  defaultPriority: ExtensionPriority.Highest,
})
export class NoteExtension extends NodeExtension<NoteOptions> {
  get name() {
    return 'note' as const
  }

  createTags() {
    return []
  }

  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride,
  ): NodeExtensionSpec {
    const dataAttributeId = 'data-note-id'

    return {
      content: 'block+',
      ...override,
      attrs: {
        ...extra.defaults(),
        id: {},
      },
      parseDOM: [
        {
          tag: `span[${dataAttributeId}]`,
          getAttrs: (node: string | Node) => {
            if (!isElementDomNode(node)) {
              return false
            }

            const id = node.getAttribute(dataAttributeId)

            return { ...extra.parse(node), id }
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        const { id } = omitExtraAttributes(
          node.attrs,
          extra,
        ) as NoteNodeAttributes

        const mentionClassName = 'blocknote'

        const attrs = {
          ...extra.dom(node),
          class: id
            ? `${mentionClassName} ${mentionClassName}-${kebabCase(id)}`
            : mentionClassName,
          [dataAttributeId]: id,
        }

        return ['span', attrs]
      },
    }
  }

  @command()
  addNotesIntoCurrentPos(notes: any[]): CommandFunction {
    return ({ tr, dispatch, state }) => {
      const notesNodes = notes
        .map((note, index) => {
          const element = htmlToProsemirrorNode({
            content: note.text,
            schema: state.schema,
          })
          return index > 0
            ? [state.schema.nodes.paragraph.create(), element]
            : [element]
        })
        .flat()
        .concat(state.schema.nodes.paragraph.create())

      notesNodes.reverse().forEach((node, index) => {
        tr.insert(tr.selection.from, node)
      })

      if (dispatch) {
        dispatch(tr)
      }

      return true
    }
  }
}

/**
 * The attrs that will be added to the node.
 * ID and label are plucked and used while attributes like href and role can be assigned as desired.
 */
export type NoteNodeAttributes = ProsemirrorAttributes<{
  id: string
}>

declare global {
  namespace Remirror {
    interface AllExtensions {
      note: NoteExtension
    }
  }
}
