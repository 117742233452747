/* eslint-disable @typescript-eslint/ban-ts-comment */
import { MailConfig, mailConfig } from 'lib/data/mail/mail.config'
import { totalRecipients, trackSendEmail } from 'lib/data/mail/mail.utils'
import { defaults, set } from 'lodash'
import { makeObservable, observable } from 'mobx'
import { Advisor, EmailAddress, EmailInput, InputMaybe } from 'types/graphql'
import { trackEvent } from '../../helpers/posthog'
import { captureExceptionSilently } from '../../helpers/sentry'
import emailService from '../services/email.service'

class Mail {
  config: MailConfig
  visible = false

  close = () => {
    this.visible = false
  }

  /**
   * Convenience method to show 'send mail' modal
   * @param cfg
   */
  send = (cfg: Partial<MailConfig>) => {
    this.config = defaults(cfg, mailConfig(true))
    this.visible = true
  }

  /* ---------- public ---------- */

  /**
   * @param redirect
   * @param hint
   * @returns
   */
  getAuthUrl = async (redirect: string, hint: string) => {
    return emailService.getAuthUrl(redirect, hint)
  }

  /**
   *
   */
  openConnectEmailUrl = async () => {
    const { email } = global.data.advisors.me as Advisor

    const [url] = await Promise.all([
      this.getAuthUrl(
        window.location.origin + '/onboard/essential/email',
        email as string,
      ),
      this.revokeEmail(),
    ])

    // @ts-ignore
    window.open(url, '_blank').focus()
  }

  /**
   * Connect an advisor email account
   * @param {*} config
   */
  connectEmail = async (code: string) => {
    const url = new URL(window.location.href)
    url.search = ''
    url.hash = ''

    const creds = await emailService.connectEmail(code, url.toString())
    set(global.data.advisors, 'me.creds', creds)
    return creds
  }

  /**
   * Revoke access to an email account
   */
  revokeEmail = async () => {
    const emailRevoked = await emailService.revokeEmail()
    set(global.data.advisors, 'me.creds', null)
    return emailRevoked
  }

  /**
   * Send an email
   * @param {*} emailTo
   * @param {*} emailFrom
   * @param {*} subject
   * @param {*} markup
   */
  sendEmail = async (config: EmailInput, readReceipt?: boolean) => {
    if (!config.from?.length) {
      return { ok: false, error: 'Sender not specified' }
    }

    if (totalRecipients(config) === 0) {
      return { ok: false, error: 'Number of Recipients is Empty' }
    }

    global.app.wait = `Sending Email`
    trackSendEmail(config)

    try {
      const emailSent = await emailService.sendEmail(config, readReceipt)

      // invoke callback if provided
      if (this.config?.onSendEmail) {
        this.config.onSendEmail(this.config)
      }

      return emailSent
    } catch (err) {
      const { body, ...data } = config
      captureExceptionSilently(err, { message: 'sendEmail', data })
      throw err
    } finally {
      global.app.wait = false
    }
  }

  /**
   * Send a test email
   * @param {*} password - for password based authentication
   * @param {*} savePassword - save the provided password or not
   */
  sendTestEmail = async () => {
    trackEvent('Sent Test Email')
    global.app.wait = `Sending Test Email`
    try {
      const sentTestEmail = await emailService.sendTestEmail()
      return sentTestEmail
    } catch (err) {
      captureExceptionSilently(err, { message: 'Test Email Error' })
      throw err
    } finally {
      global.app.wait = false
    }
  }

  constructor() {
    makeObservable(this, {
      visible: observable,
      config: observable,
    })
  }

  /**
   * @param to
   * @param cc
   * @param bcc
   * @returns
   */
  numberOfRecipients = (
    to: InputMaybe<EmailAddress>[],
    cc?: InputMaybe<InputMaybe<EmailAddress>[]>,
    bcc?: InputMaybe<InputMaybe<EmailAddress>[]>,
  ) => {
    return [...(to || []), ...(cc || []), ...(bcc || [])].length
  }
}

export default Mail
